<template>
    <el-submenu
        :index="`${isIndex}`"
        :disabled="materialsList.length === 0"
        :class="['el-submenu-materials', `el-submenu--${mode}`]"
    >
        <template slot="title">
            <nav-link
                :data="isData"
                :trigger-add="{ visible: isAdmin || isEditorMaterial, to: `/knowledge/add/${isData.id}` }"
                class="sidebar-link--title"
                @add="onAddMaterial"
            />
        </template>

        <el-menu-item-group v-loading="!isLoaded">
            <div class="sidebar__nav-loading">
                <el-tree
                    :props="treeProps"
                    :data="treeDefaultMaterialArray"
                    :load="treeDefaultLoadNode"
                    :lazy="true"
                    node-key="id"
                    class="el-submenu-materials__tree"
                >
                    <nav-link
                        slot-scope="{ node, data }"
                        :data="Object.assign(
                            {
                                name: data.title,
                                link: data.link,
                                emoji: data.emoji
                            },
                        )"
                        :tree-node="node"
                    />
                </el-tree>

                <el-tree
                    :props="treeProps"
                    :data="treeDynamicMaterialArray.current"
                    :empty-text="``"
                    :load="treeDynamicLoadNode"
                    :lazy="true"
                    :draggable="isAdmin"
                    :default-expanded-keys="treeKeySet"
                    :auto-expand-parent="false"
                    :allow-drop="TreeAllowDrop"
                    :allow-drag="TreeAllowDrag"
                    @node-drag-start="onTreeHandleDragStart"
                    @node-drag-over="onTreeHandleDragOver"
                    @node-drop="onTreeHandleDrop"
                    ref="tree"
                    node-key="id"
                    class="el-submenu-materials__tree"
                >
                    <nav-link
                        slot-scope="{ node, data }"
                        :data="Object.assign(
                            {
                                name: data.title,
                                link: data.link || `/knowledge/article/${data.id}`,
                                is_type: 'material'
                            },
                            data
                        )"
                        :draggable="isAdmin"
                        :mode-type="mode"
                        :tree-node="node"
                        :trigger-add="{ visible: isAdmin || isEditorMaterial, to: `/knowledge/add/${data.id}` }"
                        @changeIcon="onOpenPicker"
                        @changeParent="({newParent, oldParent}) => onChangeParent({newParentId: newParent, oldParentId: oldParent, node: node, data: data})"
                        @add="(dataAdd) => onAddMaterial(dataAdd, { node: node, data: data })"
                    />
                </el-tree>
            </div>
        </el-menu-item-group>
    </el-submenu>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import session from '@/api/session';
    import NavLink from '@/components/sidebar/navigation/NavLink';
    import { eventBus } from '../../../main';
    import { useBroadcastChannel } from 'vue-composable';

    export default {
        name: 'NavTreeMaterial',
        components: {
            NavLink
        },
        props: {
            isData: {
                type: Object
            },

            isIndex: {
                type: String,
                default: 'menu_materials'
            },

            isOpen: {
                type: Boolean,
                default: false
            },

            mode: {
                type: String,
                default: 'viewing'
            }
        },
        computed: {
            ...mapGetters('default_data', ['current_user', 'isAdmin', 'isEditorMaterial', 'knowledgeBaseId']),
            ...mapState('knowledge_base', ['materialsList'])
        },
        data() {
            return {
                isLoaded: false,
                broadCastMaterial: {
                    supported: null,
                    data: { value: null },
                    send: null
                },
                treeKeySet: [],
                treeDefaultMaterialArray: [],
                treeDynamicMaterialArray: {
                    current: [],
                    old: []
                },
                treeDragNodeParams: {
                    data: {},
                    parent: {}
                },
                treeProps: {
                    label: 'title',
                    isLeaf: (data, node) => !data.have_children
                },
                timer: null
            };
        },
        watch: {
            isOpen() {
                if (this.isLoaded === false) {
                    this.treeDynamicMaterialArray.current = JSON.parse(JSON.stringify(this.materialsList));
                    this.treeDynamicMaterialArray.old = JSON.parse(JSON.stringify(this.materialsList));

                    setTimeout(() => {
                        this.isLoaded = true;
                    }, 600);
                }
            },

            'broadCastMaterial.data.value'(newValue) {
                this.onBroadCastTree(newValue);
            }

            // materialsList: {
            //     handler: function(newVal, oldVal) {
            //         // this.treeDynamicMaterialArray.current = JSON.parse(JSON.stringify(newVal));
            //     },
            //     deep: true
            // }
        },
        methods: {
            ...mapMutations('knowledge_base', ['MATERIALS_LIST']),

            onChangeParent(params) {
                if (params.newParentId !== '') {
                    const newParentId = params.newParentId;
                    const oldParentId = params.oldParentId;
                    const nodeNewParent = this.$refs.tree.getNode(newParentId);

                    if (nodeNewParent) {
                        nodeNewParent.loading = true;
                    }

                    if (this.timer) {
                        clearTimeout(this.timer);
                        this.timer = null;
                    }

                    this.timer = setTimeout(async () => {
                        const node = params.node;
                        const data = params.data;
                        const newParentChildrenArray = await this.utilGetChildren(newParentId) || { data: [] };
                        const oldParentChildrenArray = node.parent.childNodes.filter(item => item.data.id !== data.id);

                        if (
                            node.parent.childNodes &&
                            node.parent.childNodes.length < 2
                        ) {
                            node.parent.expanded = false;
                        }

                        newParentChildrenArray.data.push(data);

                        const result = [
                            // new element
                            {
                                parent_id: newParentId,
                                children: this.utilChildArray(newParentChildrenArray.data)
                            },

                            // old element
                            {
                                parent_id: data.parent || null,
                                children: this.utilChildArray(oldParentChildrenArray)
                            }
                        ];

                        data.parent = newParentId;

                        this.onTreeRemove(data);
                        this.onTreeAppend(data, oldParentId);

                        await this.setDragDrop(result);

                        if (nodeNewParent) {
                            nodeNewParent.loading = false;
                        }

                        this.MATERIALS_LIST(JSON.parse(JSON.stringify(this.treeDynamicMaterialArray)));
                    }, 2300);
                }
            },

            async onAddMaterial(dataLink) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    try {
                        this.$router.push({
                            path: '/knowledge/edit/new-material/',
                            query: {
                                parent_id: dataLink.id
                            }
                        });
                    } catch (error) {
                        console.error(error);

                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            title: 'Во время создания материала, произошла ошибка. Давайте попробуем ещё раз.',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer);
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                            }
                        });
                    }
                }
            },

            async removeMaterial() {
                const materialId = this.$route.params.material_id;
                const material = await session.get(`/api/v1/material/${materialId}/`);
                const materialData = material.data;

                if (materialData.text === '<h1>Без названия</h1>') {
                    await session.delete(`/api/v1/material/${materialId}/`)
                        .then((res) => {
                            this.$store.dispatch(
                                'knowledge_base/removeMaterial',
                                {
                                    ...materialData,
                                    parent:
                                        materialData.parent ===
                                        this.knowledgeBaseId
                                            ? this.knowledgeBaseId
                                            : materialData.parent
                                }
                            );
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            },

            onBroadCastTree({ drag, drop, type }) {
                if (type === 'inner') {
                    this.treeKeySet = [drop.id];
                    this.$refs.tree.remove(drag.id);
                    this.$refs.tree.append(drag, drop.id);
                }

                if (type === 'before' || type === 'after') {
                    this.$refs.tree.remove(drag.id);

                    if (type === 'before') this.$refs.tree.insertBefore(drag, drop.id);
                    if (type === 'after') this.$refs.tree.insertAfter(drag, drop.id);
                }

                if (type === 'remove') {
                    this.$refs.tree.remove(drag.id);
                }
            },

            onTreeAppend(dataTree = {}, oldParentId) {
                let node = {};
                let dropNode = {};
                let type = 'inner';

                if (dataTree.parent !== null && dataTree.parent !== '23') {
                    node = this.$refs.tree.getNode(dataTree.parent);
                    dropNode = node.data;

                    node.expanded = true;
                    node.isLeaf = false;
                    node.isLeafByUser = false;

                    if (!dropNode.children) {
                        this.$set(dropNode, 'children', []);
                    }

                    dropNode.have_children = true;
                    dropNode.children.push(dataTree);
                } else {
                    type = 'before';
                    dropNode = this.$refs.tree.getNode(oldParentId);
                    dropNode = dropNode.data;

                    this.$refs.tree.insertBefore(dataTree, dropNode);
                }

                this.broadCastMaterial.send({
                    type: type,
                    drag: dataTree,
                    drop: dropNode
                });
            },

            onTreeChange(dataTree = {}) {
                const node = this.$refs.tree.getNode(dataTree.id);

                if (node) {
                    const nodeData = node.data || {};
                    const newData = Object.assign(nodeData, {
                        id: dataTree.id,
                        title: dataTree.title
                    });

                    node.data = newData;
                }
            },

            onTreeRemove(dataTree = {}) {
                this.broadCastMaterial.send({
                    type: 'remove',
                    drag: dataTree,
                    drop: {}
                });
                this.$refs.tree.remove(dataTree.id);
            },

            async onTreeHandleDragStart(draggingNode, ev) {
                ev.dataTransfer.dropEffect = 'move';
                ev.dataTransfer.effectAllowed = 'move';

                this.treeDragNodeParams.data = draggingNode.data;
                this.treeDragNodeParams.parent = draggingNode.parent;
            },

            async onTreeHandleDragOver(draggingNode, ev, event) {
                const height = window.innerHeight - 60;
                const screenY = event.screenY;

                if (screenY <= 200) {
                    window.scrollTo({
                        top: (window.scrollY - 23)
                    });
                }

                if (screenY >= height) {
                    window.scrollTo({
                        top: (window.scrollY + 23)
                    });
                }
            },

            async onTreeHandleDrop(draggingNode, dropNode, dropType) {
                let dropResult = [];
                let dropChildrenArray = [];

                const dragParent = this.treeDragNodeParams.parent;

                /* Переложили внутрь закрытого материала. */
                if (dropType === 'inner') {
                    dropNode.loading = true;

                    if (dropNode.loaded) {
                        dropChildrenArray = dropNode.childNodes;
                    } else {
                        dropChildrenArray = await this.utilGetChildren(dropNode.data.id);
                        dropChildrenArray = [].concat(dropChildrenArray.data, dropNode.data.children);
                    }

                    dropResult = [
                        // new element
                        {
                            parent_id: dropNode.data.id,
                            children: this.utilChildArray(dropChildrenArray)
                        },

                        // old element
                        {
                            parent_id: dragParent.data.id || null,
                            children: this.utilChildArray(dragParent.childNodes)
                        }
                    ];
                }

                /*
                    Просто переложили в другое место,
                    это может быть какой та отдельно-раскрытый материал
                    или же переставили внутри главного блока(База знаний).
                */
                if (dropType === 'after' || dropType === 'before') {
                    dropNode.parent.loading = true;

                    dropResult = [
                        // new element
                        {
                            parent_id: dropNode.data.parent,
                            children: this.utilChildArray(dropNode.parent.childNodes)
                        },

                        // old element
                        {
                            parent_id: dragParent.data.id || null,
                            children: this.utilChildArray(dragParent.childNodes)
                        }
                    ];
                }

                this.broadCastMaterial.send({
                    type: dropType,
                    drag: draggingNode.data,
                    drop: dropNode.data
                });

                await this.setDragDrop(dropResult);

                /*
                * Это своего рода костыль.
                * Детали:
                * Почему-та во время сохранения изменений в setDragDrop, ломается дерево: при переносе(after или before) начинают дублировать материалы.
                * Пришлось собрать данный костыль, чтобы он избавился от дублей в дереве. Сам же баг касается только клиентской части.
                */
                dropNode.parent.childNodes = dropNode.parent.childNodes.filter((item, index, array) => {
                    return index === dropNode.parent.childNodes.findIndex(
                        other => item.data.id === other.data.id
                    );
                });

                if (dropType === 'inner') {
                    dropNode.loading = false;
                    this.treeKeySet = [dropNode.data.id];
                } else {
                    dropNode.parent.loading = false;
                }
            },

            TreeAllowDrop(draggingNode, dropNode) {
                return !dropNode.loading && !dropNode.parent.loading;
            },

            TreeAllowDrag(draggingNode) {
                return !draggingNode.loading && !draggingNode.parent.loading;
            },

            onOpenPicker(data) {
                if (this.current_user.is_editor_material) {
                    event.preventDefault();

                    this.$store.dispatch('sidebar/setPicker', {
                        opened: false
                    });

                    const coords = event.target.getBoundingClientRect();
                    const pickerElem = document.getElementById('sidebar_picker');
                    const backgroundPickerElem = document.getElementById('sidebar_picker-background');

                    pickerElem.style.top = `${coords.y + 570 > window.screen.height ? coords.y - 425 : coords.y + 25}px`;
                    pickerElem.style.left = `${coords.x + 50}px`;
                    backgroundPickerElem.style.height = `${document.body.clientHeight}px`;
                    backgroundPickerElem.style.width = `${document.body.clientWidth}px`;

                    this.$store.dispatch('sidebar/setPicker', {
                        opened: true,
                        id: data.id,
                        content_type: 'material'
                    });
                }
            },

            async treeDynamicLoadNode(node, resolve) {
                setTimeout(async () => {
                    const data = node.data || {};

                    if (node.level === 0) {
                        return resolve(this.treeDynamicMaterialArray.current);
                    }

                    if (node.level > 0) {
                        const request = await this.utilGetChildren(data.id);
                        resolve(request.data);
                    }
                }, 400);
            },

            async treeDefaultLoadNode(node, resolve) {
                const array = [
                    {
                        title: 'Избранное',
                        link: '/knowledge/favourites',
                        emoji: 'star'
                    },
                    {
                        title: 'Статьи для Вас',
                        link: '/knowledge/recommended',
                        emoji: 'books'
                    }
                ]

                if (this.isAdmin) {
                    array.push({
                        title: 'Устаревшее',
                        link: '/knowledge/old',
                        emoji: 'hourglass'
                    });
                }

                this.treeDefaultMaterialArray = array;

                if (node.level === 0) {
                    return resolve(this.treeDefaultMaterialArray);
                }
            },

            utilChildArray(array) {
                return array.map((variable, index) => {
                    const data = variable.data || variable;

                    return {
                        id: data.id,
                        position: index
                    };
                });
            },

            async utilGetChildren(id) {
                let result = { data: [] };
                let messageError = 'Во время загрузки материалов, произошла ошибка. Перезагрузите страницу и попробуйте ещё раз.';

                await session.get(`/api/v1/knowledge-base/${id}/children/`)
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 404) {
                                messageError = 'Увы, такого раздела не существует.';
                            }

                            this.$swal.fire({
                                toast: true,
                                position: 'top-end',
                                title: messageError,
                                icon: 'error',
                                showConfirmButton: false,
                                timer: 4500,
                                timerProgressBar: true,
                                onOpen: (toast) => {
                                    toast.addEventListener('mouseenter', this.$swal.stopTimer);
                                    toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                                }
                            });
                        }
                    })
                    .then((responce) => {
                        result = responce;
                    });

                return result;
            },

            async setDragDrop(data) {
                try {
                    await session.post('/api/v1/knowledge-base/drag_and_drop/', data);

                    this.treeDynamicMaterialArray.old = JSON.parse(JSON.stringify(this.$refs.tree.root.data));

                    this.MATERIALS_LIST(JSON.parse(JSON.stringify(this.treeDynamicMaterialArray.current)));
                } catch (error) {
                    console.error(error);

                    this.treeDynamicMaterialArray.current = JSON.parse(JSON.stringify(this.treeDynamicMaterialArray.old));

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        title: 'Во время сохранения изменений, произошла ошибка. Попробуйте ещё раз или перезагрузите страницу.',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 4500,
                        timerProgressBar: true,
                        onOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer);
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                        }
                    });
                }
            }
        },

        mounted() {
            const { supported, data, send } = useBroadcastChannel('composable-material-tree');

            this.broadCastMaterial = {
                supported: supported,
                data: data,
                send: send
            };

            eventBus.$on('added-material', (material) => this.onTreeAppend(material));
            eventBus.$on('changed-material', (material) => this.onTreeChange(material));
            eventBus.$on('delete-material', (material) => this.onTreeRemove(material));
        }
    };
</script>
